import Step from './step';
import MyButton from './button';
import { AppointmentDetails } from './appointmentDetails';
import { useEffect, useState } from 'react';
import axios from 'axios';

function TimeSelector({handleNavigation, reservation, handleReservation}) {

  const stepProps = {
    stepNumber: "Step 3",
    stepMessage: "Select an available time slot."
  }
  const url = `${process.env.REACT_APP_BACKEND_SERVER}getIndividualAvailabilty/${reservation.date}`

  const [times, setTimes] = useState()
  const getTimes = () => {
    axios.get(url.toString())
    .then((response) => {
      setTimes(response.data)
    });
  }
  useEffect(() => getTimes(), [])

  function handleClick(type) {
    handleNavigation(type);
  }

  function handleTimeClick(id) {
    handleReservation({timeId: times[id].id, time: times[id].time})
    handleNavigation('continue');
  }

  function getButtons() {
    const buttons = []
    for (const time in times) {
      if (Object.hasOwnProperty.call(times, time)) {
        const element = times[time];
        buttons.push(
          <div className="form-element" key={time}>
            <MyButton
              id={time}
              l1Text={element.time}
              disabled={element.reserved}
              type="primary"
              onClick={handleTimeClick}
              />
          </div>
        );
      };
    };
    return buttons;
  }

  return (
    <div className="container">
      <div className="form">

        <div className="form-element">
          <MyButton
            id="goBack"
            l1Text="Go Back"
            onClick={handleClick}
            disabled={false}
            type="secondary" />
        </div>
        <AppointmentDetails reservation={reservation} />
        <Step {...stepProps} />
        {getButtons()}
      </div>
    </div>
  );
}

export default TimeSelector;