export function AppointmentDetails({reservation}) {

  function getDetails() {
    let details = [];
    if (reservation.date !== null) {
      const longDate = new Date(reservation.date).toUTCString().slice(0, 17)
      details.push(<p className="appointment-details-text" key={1}>Day: {longDate}</p>);
    }
    if (reservation.time !== null) {
      details.push(<p className="appointment-details-text" key={2}>Time: {reservation.time}</p>);
    }
    if (reservation.type !== null) {
      details.push(<p className="appointment-details-text" key={3}>Type: {reservation.type.capitalize()}</p>);
    }
    return details;
  }

  return (
    <div className="appointment-details-container">
      <h3 className="appointment-details">Appointment Details:</h3>
      <hr />
      <p className="appointment-details-text">Name: {reservation.fullname}</p>
      { getDetails() }
    </div>
  )
}