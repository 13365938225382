import React from 'react';
import Step from './step';
import MyButton from './button';
import { AppointmentDetails } from './appointmentDetails';
// import { Checkbox, TextField } from '@adobe/react-spectrum';
import axios from 'axios';

function Confirmation({handleNavigation, reservation, handleReservation}) {

  // const addDashesToExistingPhoneNumber = (value) => {
  //   if (value.length === 10) {
  //     let number = '';
  //     number = value.slice(0, 3) + "-" + value.slice(3);
  //     return number.slice(0, 7) + "-" + number.slice(7);
  //   }
  //   return value
  // }

  // const [cellPhone, setCellPhone] = useState(addDashesToExistingPhoneNumber(reservation.cell_phone))
  // const [textReminder, setTextReminder] = useState(reservation.text_reminder)
  const stepProps = {
    stepNumber: "Confirmation",
    stepMessage: "Confirm your appointment details above. Once you click \"Confirm\" your appointment will be scheduled."
  }

  // useEffect(() => {
  //   handleReservation({cell_phone: cellPhone, text_reminder: textReminder})
  // }, [cellPhone, textReminder, handleReservation])

  // const validatePhoneNumber = () => {
  //   var phoneno = /^\d{3}-\d{3}-\d{4}$/;
  //   return cellPhone.match(phoneno);
  // }

  // const addDashesToPhoneNumber = (value) => {
  //   if (value.length === 3) {
  //     setCellPhone(value + '-')
  //     return
  //   }
  //   if (value.length === 7) {
  //     setCellPhone(value + '-')
  //     return
  //   }
  //   setCellPhone(value)
  //   return
  // }

  // const removeDashesFromPhoneNumber = (value) => {
  //   return value.replace(/-/g, '')
  // }

  function handleClick() {
    let updatedReservation = {...reservation};
    // if (validatePhoneNumber(cellPhone) && textReminder) {
    //   updatedReservation = {...reservation, cell_phone: removeDashesFromPhoneNumber(cellPhone), text_reminder: textReminder}
    // }
    axios.post(`${process.env.REACT_APP_BACKEND_SERVER}setAppointment`, updatedReservation)
    .then((response) => {
      const data = response.data;
      if (data.error) {
        handleNavigation('noTime')
        return
      }
      handleNavigation('continue');
    })
    .catch((error) => {
      console.log('error', error)
    });
  }

  function handleBackClick() {
    handleNavigation('goBack');
  }

  return (
    <div className="container">
      <div className="form">
        <div className="form-element">
          <MyButton
            l1Text="Go Back"
            onClick={handleBackClick}
            disabled={false}
            type="secondary" />
        </div>
        <AppointmentDetails reservation={reservation} />
        <Step {...stepProps} />
        {/* <Checkbox
          isSelected={textReminder}
          onChange={setTextReminder}>Would you like a text confirmation and reminder?</Checkbox>
        <TextField
          label="Cell Phone: ex(123-456-7890)"
          value={cellPhone}
          onChange={addDashesToPhoneNumber}
          isDisabled={!textReminder}
          validationState={validatePhoneNumber() ? 'valid' : 'invalid'}/> */}
        <div className="form-element">
          <MyButton
            id="confirm"
            l1Text="Confirm"
            onClick={handleClick}
            disabled={false}
            type="primary" />
        </div>
      </div>
    </div>
  );
}

export default Confirmation;