import Step from './step';
import MyButton from './button';
import { AppointmentDetails } from './appointmentDetails';

function AppointmentType({handleNavigation, reservation, handleReservation}) {

  const stepProps = {
    stepNumber: "Step 4",
    stepMessage: "Is this appointment for you individually or your whole family?"
  }

  function handleClick(type) {
    handleReservation({type: type})
    handleNavigation('continue')
  }

  function handleBackClick(type) {
    handleNavigation('goBack')
  }

  return (
    <div className="container">
      <div className="form">
        <div className="form-element">
          <MyButton
            l1Text="Go Back"
            onClick={handleBackClick}
            disabled={false}
            type="secondary" />
        </div>
        <AppointmentDetails reservation={reservation} />
        <Step {...stepProps} />
        <div className="form-element">
          <MyButton
            id="family"
            l1Text="Family"
            onClick={handleClick}
            disabled={false}
            type="primary" />
        </div>
        <div className="form-element">
          <MyButton
            id="individual"
            l1Text="Individual"
            onClick={handleClick}
            disabled={false}
            type="primary" />
        </div>
      </div>
    </div>
  );
}

export default AppointmentType;