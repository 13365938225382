import './button.css'

function MyButton({
  id,
  l1Text,
  l2Text,
  count,
  disabled,
  onClick,
  type
}) {
  const activeButton = disabled ? "button-disabled" : "button-active"
  const buttonType = type === "primary" || "primary_date" ? "button-primary" : "button-secondary"
  if (l2Text) {
    if (type === 'primary_date') {
      return (
        <div
          className={`button button-count button-big ${buttonType} ${activeButton}`}
          onClick={disabled ? () => {} : () => {onClick(id)}}>
          <div className="count-container">
            {count}
          </div>
          <div className="text-container">
            <p className="l1">{l1Text}</p>
            <p className="l2">{l2Text}</p>
          </div>
        </div>
      )
    }
    return (
      <div
        className={`button button-big ${buttonType} ${activeButton}`}
        onClick={disabled ? () => {} : () => {onClick(id)}}>
        <p className="l1">{l1Text}</p>
        <p className="l2">{l2Text}</p>
      </div>
    );
  } else {
    return (
      <div
        className={`button ${buttonType} ${activeButton} `}
        onClick={disabled ? () => {} : () => {onClick(id)}}>
        <p className="l1">{l1Text}</p>
      </div>
    );
  }
}

export default MyButton;