import { useState } from "react";
import Step from "./step";
import MyButton from "./button";
import axios from "axios";
import { TextField, DatePicker } from "@adobe/react-spectrum";

function Authorize({ handleNavigation, reservation, handleReservation }) {
  const stepProps = {
    stepNumber: "Step 1",
    stepMessage: "Enter your last name and birthdate.",
  };

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  function handleClick(type) {
    // This is where we would check the name and birthdate to authorize the user and only continue if we successfully find a
    // valid member.
    setShowErrorMessage(false);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_SERVER}getMemberByLastNameAndBirthdate/${reservation.lastname}/${reservation.birthdate}`
      )
      .then((response) => {
        const data = response.data;
        if (data.error) {
          handleReservation();
          setShowErrorMessage(true);
          return;
        }
        setShowErrorMessage(false);
        handleReservation({
          fullname: `${data.last_name}, ${data.first_name}`,
          memberId: data.id,
          ...data,
        });
        console.log(data);
        if (data.has_appointment) {
          handleReservation({
            timeId: data.timeId,
            date: data.appointment_details.date,
            time: data.appointment_details.time,
            type: data.appointment_details.type,
          });
          handleNavigation("appointment");
          return;
        }
        handleNavigation(type);
      })
      .catch((error) => {
        handleReservation();
        setShowErrorMessage(true);
        return;
      });
  }

  return (
    <div className="container">
      <div className="form">
        <Step {...stepProps} />
        <div className="form-element">
          <TextField
            label="Last Name"
            value={reservation.lastname || ""}
            onChange={(value) => handleReservation({ lastname: value })}
          />
        </div>
        <div className="form-element">
          <DatePicker
            label="Birthday"
            value={reservation.birthday}
            onChange={(value) => handleReservation({ birthdate: value })}
          />
        </div>
        <div className="form-element">
          <MyButton
            id="continue"
            l1Text="Continue"
            onClick={handleClick}
            disabled={false}
            type="primary"
          />
        </div>
        <div>
          <p
            id="error-message"
            className="error-message"
            style={{ display: showErrorMessage ? "block" : "none" }}
          >
            An error has occured. Double check the spelling of your name and
            your birthdate. If you still have issues please contact the ward
            clerk.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Authorize;
