import Step from './step';
import MyButton from './button';
import { AppointmentDetails } from './appointmentDetails';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';

function DateSelector({handleNavigation, reservation, handleReservation}) {

  const stepProps = {
    stepNumber: "Step 2",
    stepMessage: "Select an available date."
  }

  const [loading, setLoading] = useState(true)
  const [dates, setDates] = useState()
  const getDates = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_SERVER}getAllAvailability`)
    .then((response) => {
      setDates(response.data)
      setLoading(false)
    });
  }
  useEffect(() => {getDates()}, [])

  function handleClick(type) {
    handleNavigation(type);
  }

  function handleDateClick(id) {
    handleReservation({dateId: dates[id].id, date: dates[id].date})
    handleNavigation('continue');
  }

  function getButtons() {
    const buttons = [];
    for (const date in dates) {
      if (Object.hasOwnProperty.call(dates, date)) {
        const element = dates[date];
        if (element.count === 0) continue;
        const longDate = new Date(element.date).toUTCString().slice(0, 17)
        buttons.push(
          <div className="form-element" key={date}>
            <MyButton
              id={date}
              l1Text={longDate}
              l2Text={element.times}
              count={element.count}
              disabled={!element.available}
              type="primary_date"
              onClick={handleDateClick}
              />
          </div>
        );
      };
    };
    return buttons;
  }
  function getSpinner() {
    return (
      <div className="spinner-container">
        <p className="stepMessage">Loading, please wait.</p>
        <Oval
          height={100}
          width={100}
          color="#01b6d1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#b9e4ed"
          strokeWidth={5}
          strokeWidthSecondary={1}
        />
      </div>)
  }

  return (
    <div className="container">
      <div className="form">

        <div className="form-element">
          <MyButton
            id="goBack"
            l1Text="Go Back"
            onClick={handleClick}
            disabled={false}
            type="secondary" />
        </div>
        <AppointmentDetails reservation={reservation} />
        <Step {...stepProps} />
        { loading ? getSpinner() : getButtons() }
      </div>
    </div>
  );
}

export default DateSelector;