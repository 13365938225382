import './step.css';

function Step({stepNumber, stepMessage}) {
  return (
    <div >
      <h3>{ stepNumber }:</h3>
      <hr />
      <p className="stepMessage">{ stepMessage }</p>
    </div>
  );
}

export default Step;