import Step from "./step";
import MyButton from "./button";
import { AppointmentDetails } from "./appointmentDetails";
import axios from "axios";

function Appointment({ handleNavigation, reservation, handleReservation }) {
  const stepProps = {
    stepNumber: "Appointment Details",
    stepMessage: "Your appointment has been confirmed and scheduled.",
  };

  function handleClick(type) {
    handleReservation();
    handleNavigation("complete");
  }

  function handleCancel() {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_SERVER}cancelAppointment/${reservation.memberId}`
      )
      .then((response) => {
        handleNavigation("complete");
      });
  }

  return (
    <div className="container">
      <div className="form">
        <AppointmentDetails reservation={reservation} />
        <Step {...stepProps} />
        <div>
          <p>
            You can access you appointment details and cancel you appointment at
            any time by visiting{" "}
            <a href="https://www.springlake4thward.org">
              springlake4thward.org
            </a>{" "}
            and siging back in with the same name and birthdate used previously.
          </p>
          <hr />
          <p>
            Prior to your appointment, please obatin and verify your donations
            statement by going to:
          </p>
          <a
            href="https://donations.churchofjesuschrist.org/donations/#/reports"
            target="_blank"
          >
            https://donations.churchofjesuschrist.org/donations/#/reports
          </a>
          <p>
            <strong>Steps:</strong>
          </p>
          <p>&#9;– Sign in using your Churchofjesuschrist.org account</p>
          <p>
            &#9;– Select <strong>"Statement of Contributions"</strong>
          </p>
          <p>
            &#9;– Select <strong>"Current Year"</strong>
          </p>
          <p>
            &#9;– Click <strong>"Generate Statement"</strong>
          </p>
          <p>
            A PDF will be generated that you can open to view your donations and
            compare with your personal records.
          </p>
        </div>
        <div className="form-element">
          <MyButton
            id="complete"
            l1Text="All Done"
            onClick={handleClick}
            disabled={false}
            type="primary"
          />
        </div>
        <div className="form-element">
          <MyButton
            id="complete"
            l1Text="Cancel Appointment"
            onClick={handleCancel}
            disabled={false}
            type="button-secondary"
          />
        </div>
      </div>
    </div>
  );
}

export default Appointment;
