import './header.css';

function Header() {
  return (
    <div className="header">
      <h1>Spring Lake 4th Ward</h1>
      <h2>Tithing Declaration Sign-up</h2>
    </div>
  );
}

export default Header;