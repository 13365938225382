import { useEffect, useState } from 'react';
import './App.css';
import Appointment from './components/appointment'
import Authorize from './components/authorize';
import DateSelector from './components/dateSelector';
import TimeSelector from './components/timeSelector';
import AppointmentType from './components/appointmentType';
import Confirmation from './components/confirmation';
import FinalScreen from './components/finalScreen';
import Header from './components/header';
import ScheduledAppointments from './components/ScheduledAppointments';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider, defaultTheme } from '@adobe/react-spectrum';

/* eslint-disable */
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}
/* eslint-enable */

function App() {

  const initialState = {
    memberId: null,
    lastname: null,
    fullname: null,
    birthdate: null,
    date: null,
    dateId: null,
    time: null,
    timeId: null,
    type: null,
    email: null,
    cell_phone: null,
    text_reminder: null
  }

  const [reservation, setReservation] = useState(initialState);

  const handleReservation = (value) => {
    if (!value) {
      setReservation(() => {
        return {...initialState}
      });
      return;
    }
    setReservation((prevState) => {
      return {...prevState, ...value};
    });
  };

  const stepMap = {
    1: Authorize,
    2: DateSelector,
    3: TimeSelector,
    4: AppointmentType,
    5: Confirmation,
    6: FinalScreen,
    7: Appointment,
  };

  const [currentStep, setCurrentStep] = useState(1);
  useEffect(() => { setCurrentStep(1); }, []);

  let CurrentComponent = stepMap[currentStep]

  function handleNavigation(type) {
    if (type === 'goBack') {
      if (currentStep !== 1) {
        CurrentComponent = stepMap[currentStep - 1];
        setCurrentStep(currentStep - 1);
      };
    }
    else if (type === 'continue') {
      if (currentStep !== 6 || currentStep !== 5) {
        CurrentComponent = stepMap[currentStep + 1];
        setCurrentStep(currentStep + 1);
      };
    } else if (type === 'complete') {
      setCurrentStep(1);
      CurrentComponent = stepMap[currentStep];
    } else if (type === 'noTime') {
      CurrentComponent = stepMap[currentStep - 2];
      setCurrentStep(currentStep - 2)
    } else if (type === 'appointment') {
      CurrentComponent = stepMap[7];
      setCurrentStep(7)
    }
  }

  return (
    <div>
      <Provider theme={defaultTheme}>
        <div className="full-height">
        <Header />
        <div className="app-container">
          <BrowserRouter>
            <Routes>
              <Route path="/tithingsettlement/appointments/" element={<ScheduledAppointments />} />
              <Route path="/" element={<CurrentComponent handleNavigation={handleNavigation} reservation={reservation} handleReservation={handleReservation} />} />
            </Routes>
          </BrowserRouter>
        </div>
        </div>
      </Provider>
    </div>
  );
}

export default App;
