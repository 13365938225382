import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

function ScheduledAppointments() {
  const { search } = useLocation();
  const split_search = search.split('&');
  const user = split_search[0]?.match(/user=(.*)/)?.[1];
  const password = split_search[1]?.match(/password=(.*)/)?.[1];
  console.log(user, password)


  const url = `${process.env.REACT_APP_BACKEND_SERVER}getAllAppointments/${user}/${password}`;
  const [appointments, setAppointments] = useState();
  const getAppointments = () => {
    axios.get(url.toString())
    .then((response) => {
      console.log(response.data);
      setAppointments(response.data);
    });
  }

  const removeAppointmentFromList = (memberId) => {
    const newAppointments = appointments.filter((appointment) => appointment.memberId !== memberId);
    setAppointments(newAppointments);
  }

  const cancelAppointment = (memberId, memberName) => {
    const verifyCancel = window.confirm(`Are you sure you want to cancel this appointment for: ${memberName}? This cannot be undone.`)
    if (!verifyCancel) return;
    axios.delete(`${process.env.REACT_APP_BACKEND_SERVER}cancelAppointment/${memberId}`)
    .then((response) => {
      console.log(response.data);
      removeAppointmentFromList(memberId);
      // getAppointments();
    });
  }

  function showAppointments() {
    const appointment_rows = []
    appointment_rows.push(
      <div className="appointment_rows" key="header">
        <h2>Total Number: {appointments?.length}</h2>
        <hr />
      </div>
    )
    for (const appointment in appointments) {
      if (Object.hasOwnProperty.call(appointments, appointment)) {
        const element = appointments[appointment];
        const longDate = new Date(element.date).toUTCString().slice(0, 17)
        appointment_rows.push(
          <div className="appointment-row" key={element.memberId}>
            <div className="appointment-details">
              <p className="row">{longDate + ' ' + element.time}</p>
              <p className="row">{`${element.type.toUpperCase()}: ${element.name}`}</p>
            </div>
            <button className="small-button" onClick={() => cancelAppointment(element.memberId, element.name)}>Cancel</button>
          </div>
        )
      }
    }
    return appointment_rows
  }

  useEffect(() => getAppointments(), [])

  return (
    <div className="appointment-container">
      {showAppointments()}
    </div>
  )
}

export default ScheduledAppointments;